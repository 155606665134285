<template>
  <div style="height: 100%;background: #f7f8fa;">
    <van-nav-bar
      :title="item.carNo + ' 爱车要项'"
      :z-index="999"
      :fixed="true"
      left-arrow
      @click-left="back"
    />
    <div class="tops">
      <van-cell
        title="交强险到期"
        :value="item.insuranceExpire?item.insuranceExpire:'请设置'"
        @click="toshow(1)"
      />
      <van-cell
        title="商业险到期"
        :value="item.businessExpire?item.businessExpire:'请设置'"
        @click="toshow(2)"
      />
      <van-cell title="年审到期" :value="item.yearExpire?item.yearExpire:'请设置'" @click="toshow(3)" />
      <van-cell
        title="保养到期"
        :value="item.suggestMaintenanceDate?item.suggestMaintenanceDate:'请设置'"
        @click="toshow(4)"
      />
      <van-calendar
        v-model="show"
        :min-date="minDate"
        :max-date="maxDate"
        :show-confirm="false"
        @confirm="onConfirm"
      />
    </div>

    <div class="bott">
      <div class="bott_c" v-preventReClick @click="tobaoocun">保 存</div>
    </div>
  </div>
</template>

<script>
import { carupdate } from "../../api/check";
import preventReClick from "../../assets/preventReClick";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      loading: false,
      finished: true,
      shangjias: JSON.parse(sessionStorage.getItem("shangjia")),
      currentDate: new Date(),
      active: 2,
      show: false,
      show2: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 0, 31),
      idx: 0,
      date: "",
      item: {}
    };
  },

  methods: {
    toshow(idx) {
      this.idx = idx;
      this.show = true;
    },
    formatDate(date) {
      console.log(date);
      var dateyue = date.getMonth() + 1;
      var datetian = date.getDate();
      if (dateyue < 10) {
        dateyue = "0" + dateyue;
      }
      if (datetian < 10) {
        datetian = "0" + datetian;
      }
      return `${date.getFullYear()}-${dateyue}-${datetian}`;
    },
    onConfirm(date) {
      this.show = false;
      if (this.idx == 1) {
        this.item.insuranceExpire = this.formatDate(date);
      } else if (this.idx == 2) {
        this.item.businessExpire = this.formatDate(date);
      } else if (this.idx == 3) {
        this.item.yearExpire = this.formatDate(date);
      } else if (this.idx == 4) {
        this.item.suggestMaintenanceDate = this.formatDate(date);
      }

      console.log(this.formatDate(date));
    },
    tobaoocun() {
      var _this = this
      carupdate(this.item).then(e => {
        if (e.code == 200) {
          this.$toast.success("保存成功！");
          setTimeout(() => {
            _this.$router.go(-1);
          }, 1200);
        }
      });
    },
    back() {
        this.$router.go(-1);
    }
  },
  created() {
    this.item = JSON.parse(this.$route.query.item);
    console.log(this.item);
    // this.onLoad()
  }
};
</script>

<style lang="less" scoped>
.tops {
  padding: 46px 0px;
}
// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}
</style>
